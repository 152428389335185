import { useState, useEffect } from 'react';
import {
    collection,
    query,
    where,
    getDocs,
    doc,
    deleteDoc,
    updateDoc,
    arrayUnion,
    arrayRemove,
    addDoc,
    getDoc
} from "firebase/firestore";
import { db } from '../firebaseInit';
import { v4 as uuidv4 } from 'uuid';

export const useBrokerStore = (accountId) => {
    const [brokers, setBrokers] = useState([]);
    const [cacheLastInvalidatedAt, setCacheLastInvalidatedAt] = useState(Date.now());
    const [currentBroker, setCurrentBroker] = useState(null);


    useEffect(() => {
        const fetchBrokers = async () => {
            try {
                console.log('useBrokerStore fetchBrokers: ', accountId)
                const q = query(collection(db, "brokers"), where("userId", "==", accountId));

                const querySnapshot = await getDocs(q);

                const brokersList = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                console.log('useBrokerStore brokersList: ', brokersList)

                setBrokers(brokersList);
            } catch (error) {
                console.error("Error fetching expenses: ", error);
            }
        };

        if (accountId) {
            fetchBrokers();
        }
    }, [accountId, cacheLastInvalidatedAt]);

    const addBroker = async ({name, currency, currentNavInBrokerCurrencyInCents}) => {
        await addDoc(collection(db, "brokers"), {
            name,
            currency,
            currentNavInBrokerCurrencyInCents,
            userId: accountId,
        });
        setCacheLastInvalidatedAt(Date.now())
    }

    const selectBroker = async (x) => {
        setCurrentBroker(x);
    }

    const updateBroker = async ({id, name, currency, currentNavInBrokerCurrencyInCents}) => {
        const ref = doc(db, "brokers", id);

        // Check if the document exists in the database
        const docSnap = await getDoc(ref);
        if (!docSnap.exists()) {
            throw new Error("Broker with such id doesn't exist");
        }

        await updateDoc(ref, {
            name,
            currency,
            currentNavInBrokerCurrencyInCents,
        });
        setCacheLastInvalidatedAt(Date.now())
    }

    const deleteCurrentBroker = async () => {
        if (currentBroker) {
            const ref = doc(db, "brokers", currentBroker.id);
            await deleteDoc(ref);
            setCurrentBroker(null);
            setCacheLastInvalidatedAt(Date.now())
        }
    }

    return { brokers, addBroker, currentBroker, selectBroker, updateBroker, deleteCurrentBroker};
};

