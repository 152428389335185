import { useState, useEffect } from 'react';
import {
    collection,
    query,
    where,
    getDocs,
    doc,
    deleteDoc,
    updateDoc,
    arrayUnion,
    arrayRemove,
    addDoc,
    getDoc
} from "firebase/firestore";
import { db } from '../firebaseInit';
import { v4 as uuidv4 } from 'uuid';

export const useJournalStore = (accountId) => {
    const [journalEntries, setJournalEntries] = useState([]);
    const [cacheLastInvalidatedAt, setCacheLastInvalidatedAt] = useState(Date.now());


    useEffect(() => {
        const fetchJournalEntries = async () => {
            try {
                console.log('useJournalStore journalEntries: ', accountId)
                const q = query(collection(db, "journalEntries"), where("userId", "==", accountId));

                const querySnapshot = await getDocs(q);

                const list = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                const sortedList = list.map(x => ({
                    ...x,
                    createdAt: new Date(x.createdAt)
                })).sort((a, b) => b.createdAt - a.createdAt);

                console.log('useJournalStore journalEntries: ', sortedList)

                setJournalEntries(sortedList);
            } catch (error) {
                console.error("Error fetching expenses: ", error);
            }
        };

        if (accountId) {
            fetchJournalEntries();
        }
    }, [accountId, cacheLastInvalidatedAt]);

    const addJournalEntry = async ({content}) => {
        await addDoc(collection(db, "journalEntries"), {
            content,
            userId: accountId,
            createdAt: (new Date()).toISOString()
        });
        setCacheLastInvalidatedAt(Date.now())
    }

    return { journalEntries, addJournalEntry};
};

