
// returns array of ranges (months) for a given year
// format: [{start: '2021-01-01', end: '2021-01-31'}, ...]
import {CheckCircleIcon, InformationCircleIcon, XCircleIcon} from "@heroicons/react/16/solid";
import {formatMoney} from "./utils";

export const generateRanges = (year) => {
    const ranges = []
    for (let i = 0; i < 12; i++) {
        const start = new Date(year, i, 1)
        const end = new Date(year, i + 1, 0)
        ranges.push({start, end, deposits: []})
    }
    return ranges
}

// returns array of ranges, distributing deposit amount over the ranges
// inputs:
//  - ranges: array of ranges  [{start: '2021-01-01', end: '2021-01-31'}, ...]
//  - deposit: {costInCents: 100000, loggedAt: '2021-01-02T00:00:00.000Z'}
//  - expectedAmount: 150000
// format: [{start: '2021-01-01', end: '2021-01-31', deposits: []}, ...]
export const allocateDeposit = (ranges, deposit, expectedAmount) => {

    let remainingAmount = deposit.costInCents

    const findRangeIndex = (ranges, deposit) => {
        return ranges.findIndex(range => {
            return range.start <= deposit.loggedAt && range.end >= deposit.loggedAt
        })
    }
    let initialRangeIndex = findRangeIndex(ranges, deposit)
    let i = initialRangeIndex

    while (i >= 0) {
        if (ranges[i].costInCents >= expectedAmount) {
            return
        }

        const amount = Math.min(expectedAmount - ranges[i].deposits.reduce((acc, d) => acc + d.costInCents, 0), remainingAmount)
        ranges[i].deposits.push({costInCents: amount, loggedAt: deposit.loggedAt})
        remainingAmount -= amount
        i--
    }

    let j = initialRangeIndex
    while (j < ranges.length && remainingAmount > 0) {
            if (ranges[j].costInCents >= expectedAmount) {
                return
            }

            const amount = Math.min(expectedAmount - ranges[j].deposits.reduce((acc, d) => acc + d.costInCents, 0), remainingAmount)
            ranges[j].deposits.push({costInCents: amount, loggedAt: deposit.loggedAt})
            remainingAmount -= amount
        j++
    }

    if (remainingAmount > 0) {
        ranges[ranges.length - 1].deposits.push({costInCents: remainingAmount, loggedAt: deposit.loggedAt})
    }
}

export const allocateDeposits = (ranges, deposits, expectedAmount) => {
    deposits.forEach(deposit => {
        allocateDeposit(ranges, deposit, expectedAmount)
    })
    return ranges
}

// Remove ranges from future months that have no deposits
export const compactRanges = (ranges) => {
    return ranges.filter(range => range.deposits.length > 0 || (new Date() >= range.start && new Date() <= range.end))
}

// const timeline = [
//     {
//         id: 1,
//         content: 'No deposit for this month',
//         target: '',
//         href: '#',
//         date: 'Jan 1',
//         datetime: '2020-09-20',
//         icon: XCircleIcon,
//         iconBackground: 'bg-gray-400',
//     },
export const convertRangesToTimeline = (ranges, expectedAmountInCents) => {
    const timeline = []
    ranges.forEach(range => {
        if (range.deposits.reduce((acc, d) => acc + d.costInCents, 0) === 0) {
            timeline.push({
                id: timeline.length + 1,
                content: 'No deposit',
                target: '',
                href: '#',
                date: range.start.toLocaleDateString('en-US', {month: 'short', day: 'numeric'}),
                datetime: range.start.toISOString(),
                icon: XCircleIcon,
                iconBackground: 'bg-gray-400',
            })
        } else {
            const context = range.deposits.filter(d => d.costInCents > 0).map(d => `${formatMoney(d.costInCents/100)} on ${new Date(d.loggedAt).toLocaleDateString('en-US', {month: 'short', day: 'numeric'})}`)
            const totalDeposited = range.deposits.reduce((acc, d) => acc + d.costInCents, 0)
            const isCompleted = totalDeposited >= expectedAmountInCents
            console.log('expectedAmount', expectedAmountInCents)

                timeline.push({
                    id: timeline.length + 1,
                    content: `You Deposited ${context.join(', ')}`,
                    target: '',
                    href: '#',
                    date: range.start.toLocaleDateString('en-US', {month: 'short', day: 'numeric'}),
                    datetime: range.start.toISOString(),
                    icon: isCompleted ? CheckCircleIcon : InformationCircleIcon,
                    iconBackground: isCompleted ? 'bg-green-400' : `bg-orange-400`,
                })
        }
    })
    return timeline
}



