import {useCallback, useState} from "react";
import axios from "axios";
import {isEmpty} from "lodash";


export const useCurrencyExchange = () => {
    const [baseCurrency, setBaseCurrency] = useState(null);
    const [exchangeRates, setExchangeRates] = useState({});
    const [isReady, setIsReady] = useState(false);

    const getExchangeRate = useCallback(async (currency) => {
        const currencyLower = currency.toLowerCase();

        if (!isEmpty(exchangeRates[currencyLower])) {
            return exchangeRates[currencyLower];
        }

        const response = await axios.get(`https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/${currencyLower}.json`, {
            mode: 'no-cors',
        });
        console.log('useCurrencyExchange:response', response)
        setBaseCurrency(currency.toLowerCase())
        setExchangeRates({
            ...exchangeRates,
            [currencyLower]: response.data[currencyLower]
        });
        setIsReady(true)
    }, [exchangeRates]);

    const convert = useCallback((amount, currency) => {
        return amount / exchangeRates[baseCurrency][currency.toLowerCase()];
    }, [baseCurrency, exchangeRates]);

    return {convert, getExchangeRate, isReady};
}

