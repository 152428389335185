/*
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
'use client'

import { useState } from 'react'
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
} from '@headlessui/react'
import {
    Bars3Icon,
    XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import {useAuth} from "../providers/authprovider";
import {usePortfolioNavUpdates} from "../hooks/usePortfolioNavUpdates";
import useUserDataStore from "../hooks/useUserDataStore";
import useExpenses from "../hooks/useExpenses";
import {signOut} from "firebase/auth";
import {auth} from "../firebaseInit";
import {
    dbExpensesToSteps,
    expensesTotal,
    formatMoney,
    getLastEntry,
    getMonthlyPassiveIncomeFromNavUpdate
} from "../utils/utils";
import Expenses from "../components/Expenses";
import ExpenseDeleteConfirmation from "../components/ExpenseDeleteConfirmation";
import {ExepenseUpdateSidebar} from "../components/ExepenseUpdateSidebar";
import {NewExpenseSidebar} from "../components/NewExpenseSidebar";
import {UpdateInvestmentAmountSidebar} from "../components/UpdateInvestmentAmountSidebar";
import {InvestmentPlanSidebar} from "../components/InvestementPlanSidebar";
import {InvestmentPlanAmountSidebar} from "../components/Sidebars/InvestmentPlanAmountSidebar";
import GuidePopup from "../components/GuidePopup";
import {
    BanknotesIcon,
    BuildingLibraryIcon,
    CreditCardIcon, EllipsisHorizontalIcon,
    HomeIcon,
    PencilIcon,
    PencilSquareIcon
} from "@heroicons/react/16/solid";
import {PassiveIncomeSidebar} from "../components/PassiveIncomeSidebar";
import {usePassiveIncomeEntries} from "../hooks/usePassiveIncomeEntries";
import {Navigate} from "react-router-dom";
import {Layout} from "../components/Layout";
import useLocalState from "../hooks/useLocalState";

const DEFAULT_YIELD = 4

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function Stats(props) {

    const tabs = [
        { name: 'Stats', href: '#', current: true },
        { name: 'Goals', href: '#', current: false },
    ]

    return <dl className="">
        <div
            id="portfolioValue"
            className="fk-pencil-block cursor-pointer mb-5 relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6"
            onClick={props.onClick}
        >
            <EllipsisHorizontalIcon aria-hidden="true" className="rotate-90 absolute h-6 w-6 text-gray-500 right-4 top-6"/>
            <dt>
                <div className="absolute rounded-md bg-indigo-500 p-3">
                    <BuildingLibraryIcon aria-hidden="true" className="h-6 w-6 text-white"/>
                </div>
                <p className="ml-16 truncate text-sm font-medium text-gray-500">Portfolio Value</p>
                <div className=" invisible absolute rounded-md p-3 right-0 top-0 hover:visible">
                    <PencilSquareIcon aria-hidden="true" className="fk-pencil h-6 w-6 text-indigo-500"/>
                </div>
            </dt>
            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                <p className="text-2xl font-semibold text-gray-900">
                    {formatMoney(props.amount)}
                </p>
            </dd>
        </div>
        <div
            id="monthlyWithdrawalNumber"
            className="cursor-pointer mb-5 relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6"
            onClick={() => props.setIsPassiveIncomeSidebarOpen(true)}
        >
            <EllipsisHorizontalIcon aria-hidden="true" className="rotate-90 absolute h-6 w-6 text-gray-500 right-3 top-6"/>
            <dt>
                <div className="absolute rounded-md bg-indigo-500 p-3">
                    <BanknotesIcon aria-hidden="true" className="h-6 w-6 text-white"/>
                </div>
                <p className="ml-16 truncate text-sm font-medium text-gray-500">Monthly Withdrawal Number</p>
            </dt>
            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                <p className="text-2xl font-semibold text-gray-900">
                    {formatMoney(props.amount1)}
                </p>
            </dd>
        </div>
        <div
            id="monthlyInvestmentPlan"
            className="cursor-pointer mb-5 relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6"
            onClick={props.onClick1}
        >
            <EllipsisHorizontalIcon aria-hidden="true" className="rotate-90 absolute h-6 w-6 text-gray-500 right-4 top-6"/>
            <dt>
                <div className="absolute rounded-md bg-indigo-500 p-3">
                    <CreditCardIcon aria-hidden="true" className="h-6 w-6 text-white"/>
                </div>
                <p className="ml-16 truncate text-sm font-medium text-gray-500">Monthly investment plan</p>
            </dt>
            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                <p className="text-2xl font-semibold text-gray-900">
                    {props.userData.investmentPlanAmount ? formatMoney(props.userData.investmentPlanAmount) : formatMoney(0)}
                </p>
            </dd>
        </div>
        {/*<div key={"amountInvested"}*/}
        {/*     onClick={() => setIsInvestementAmountSidebarOpen(true)}*/}
        {/*     className="mb-5 overflow-hidden rounded-lg px-4 py-5 bg-white shadow sm:p-6 hover:bg-indigo-50 hover:cursor-pointer">*/}
        {/*    <BanknotesIcon aria-hidden="true" className="h-6 w-6 text-white" />*/}
        {/*    <dt className="truncate text-sm font-medium text-gray-500">Portfolio value</dt>*/}
        {/*    <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">*/}
        {/*        {formatMoney(lastEntryAmount)}*/}
        {/*    </dd>*/}
        {/*</div>*/}
        {/*<div key={"yield"} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">*/}
        {/*    <dt className="truncate text-sm font-medium text-gray-500">Passive income Yield</dt>*/}
        {/*    <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{userData.yield}%</dd>*/}
        {/*</div>*/}
        {/*<div key={"monthlyIncome"} className="mb-5 overflow-hidden  bg-white rounded-lg px-4 py-5 shadow sm:p-6">*/}
        {/*    <dt className="truncate text-sm font-medium text-gray-500">Monthly passive income (4% rule)</dt>*/}
        {/*    <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{*/}
        {/*        formatMoney(monthlyPassiveIncome)*/}
        {/*    }</dd>*/}
        {/*</div>*/}
        {/*<div key={"monthlyIncome"} className="mb-5 overflow-hidden rounded-lg px-4 py-5 shadow sm:p-6">*/}
        {/*    <dt className="truncate text-sm font-medium text-gray-500">Monthly passive income (4% rule)</dt>*/}
        {/*    <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{*/}
        {/*        formatMoney(total)*/}
        {/*    }</dd>*/}
        {/*</div>*/}
        {/*<div key={"yield"}*/}
        {/*     onClick={() => setIsInvestmentPlanAmountSidebarOpen(true)}*/}
        {/*     className="mb-5 overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 hover:bg-indigo-50 hover:cursor-pointer">*/}
        {/*    <dt className="truncate text-sm font-medium text-gray-500">Monthly investment plan</dt>*/}
        {/*    <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">*/}
        {/*        {userData.investmentPlanAmount ? formatMoney(userData.investmentPlanAmount) : formatMoney(0)}*/}
        {/*    </dd>*/}
        {/*</div>*/}
    </dl>;
}

export function MainApp() {

    const authData = useAuth()
    const {
        userData,
        updateInvestmentPlanAmount,
        addInvestmentPlanEntry,
        removeInvestmentPlanEntry,
        syncUserData
    } = useUserDataStore()
    console.log('Layout: userData', userData?.accountId)
    const portfolioNavStore = usePortfolioNavUpdates(userData?.accountId)

    const [isPassiveIncomeSidebarOpen, setIsPassiveIncomeSidebarOpen] = useState(false)

    const [isNewExpenseSidebarOpen, setIsNewExpenseSidebarOpen] = useState(false)
    const [isUpdateExpenseSidebarOpen, setIsUpdateExpenseSidebarOpen] = useState(false)
    const [isDeleteExpenseSidebarOpen, setIsDeleteExpenseSidebarOpen] = useState(false)
    const [isUpdateInvestementAmountSidebarOpen, setIsInvestementAmountSidebarOpen] = useState(false)
    const [isInvestmentPlanSidebarOpen, setIsInvestmentPlanSidebarOpen] = useState(false)
    const [isInvestmentPlanAmountSidebarOpen, setIsInvestmentPlanAmountSidebarOpen] = useState(false)
    const [isGuidePopupOpen, setIsGuidePopupOpen] = useState(false)
    // const updateExpenseSidebarData = useUpdateExpenseSidebar()
    const expenseStore = useExpenses(userData?.accountId)
    const passiveIncomeStore = usePassiveIncomeEntries(userData?.accountId)
    const [cumulative, setCumulative] = useState(true)

    const {expenses, currentExpense, controls} = expenseStore


    console.log('App auth: ', authData)

    const liquidPassiveIncome = portfolioNavStore && userData ? getMonthlyPassiveIncomeFromNavUpdate(portfolioNavStore.navUpdates, userData.yield || DEFAULT_YIELD) : 0
    const monthlyPassiveIncome = liquidPassiveIncome + (passiveIncomeStore.passiveIncomeEntriesTotal / 100)

    // Calculate rest of income
    let restOfIncome = monthlyPassiveIncome

    // Convert expenses to render data
    const expensesAsSteps = expenses.length ? dbExpensesToSteps(expenses, restOfIncome, cumulative) : []

    if (!authData?.currentUser || !userData) {
        if (!authData || authData.isLoading) {
            return null
        }

        if  (!authData?.currentUser) {
            return <Navigate to="/login" replace/>
        }

        return null
    }

    const user = {
        name: authData.currentUser.displayName,
        email: authData.currentUser.email,
        imageUrl: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    }

    const lastEntry = getLastEntry(portfolioNavStore.navUpdates)
    const lastEntryAmount = (lastEntry?.amountInCents || 0) / 100

    const updateExpense = (id) => {
        const expense = expenses.find((e) => e.id == id)
        console.log('App:updateExpense:', expense)
        controls.selectExpense(expense)
        setIsUpdateExpenseSidebarOpen(true)
    }


    return (
            <Layout pageSlug="dashboard"
                    aside={<Stats onClick={() => setIsInvestementAmountSidebarOpen(true)} amount={lastEntryAmount}
                           amount1={monthlyPassiveIncome} onClick1={() => setIsInvestmentPlanAmountSidebarOpen(true)}
                           setIsPassiveIncomeSidebarOpen={setIsPassiveIncomeSidebarOpen}
                           userData={userData}/>}>
                    <div className="lg:hidden px-4 pt-6 border-b-2">
                    <Stats onClick={() => setIsInvestementAmountSidebarOpen(true)} amount={lastEntryAmount}
                           amount1={monthlyPassiveIncome} onClick1={() => setIsInvestmentPlanAmountSidebarOpen(true)}
                           setIsPassiveIncomeSidebarOpen={setIsPassiveIncomeSidebarOpen}
                           userData={userData}/>
                </div>
                <div className="px-4 py-6 sm:px-6 lg:px-8 lg:py-6">
                    <Expenses monthlyPassiveIncome={monthlyPassiveIncome} expenseStore={expenseStore}
                              openExpenseForm={setIsNewExpenseSidebarOpen}
                              setIsGuidePopupOpen={setIsGuidePopupOpen}
                              expensesAsSteps={expensesAsSteps}
                              cumulative={cumulative}
                              setCumulative={setCumulative}
                              openDeleteExpenseDialog={(expense) => {
                                  controls.selectExpense(expense)
                                  setIsDeleteExpenseSidebarOpen(true)
                              }} expenses={expenses} topExpense={() => {
                    }}
                              updateExpense={updateExpense}
                              setIsInvestmentPlanSidebarOpen={setIsInvestmentPlanSidebarOpen}
                    />
                </div>

                <ExpenseDeleteConfirmation expense={currentExpense} controls={controls}
                                           isOpen={isDeleteExpenseSidebarOpen}
                                           setIsOpen={setIsDeleteExpenseSidebarOpen}/>
                <ExepenseUpdateSidebar expense={currentExpense} controls={controls}
                                       isOpen={isUpdateExpenseSidebarOpen}
                                       setIsOpen={setIsUpdateExpenseSidebarOpen}/>
                <NewExpenseSidebar controls={controls} isOpen={isNewExpenseSidebarOpen}
                                   setIsOpen={setIsNewExpenseSidebarOpen}/>
                <UpdateInvestmentAmountSidebar
                    {...{
                        portfolioNavStore,
                        isOpen: isUpdateInvestementAmountSidebarOpen,
                        setIsOpen: setIsInvestementAmountSidebarOpen,
                    }}
                />
                {userData && <PassiveIncomeSidebar
                    {...{
                        userData,
                        isOpen: isPassiveIncomeSidebarOpen,
                        setIsOpen: setIsPassiveIncomeSidebarOpen,
                        monthlyPassiveIncome,
                        liquidPassiveIncome,
                        portfolioNav: lastEntryAmount,
                        passiveIncomeEntries: passiveIncomeStore.passiveIncomeEntries,
                        addPassiveIncomeEntry: passiveIncomeStore.addPassiveIncomeEntry,
                        removePassiveIncomeEntry: passiveIncomeStore.removePassiveIncomeEntry,
                    }}
                />}
                {userData && <InvestmentPlanSidebar
                    {...{
                        userData,
                        syncUserData,
                        addInvestmentPlanEntry,
                        removeInvestmentPlanEntry,
                        isOpen: isInvestmentPlanSidebarOpen,
                        setIsOpen: setIsInvestmentPlanSidebarOpen,
                        portfolioNavStore
                    }} />}
                {userData && <InvestmentPlanAmountSidebar
                    {...{
                        amount: userData.investmentPlanAmount,
                        userData,
                        updateInvestmentPlanAmount: updateInvestmentPlanAmount,
                        isOpen: isInvestmentPlanAmountSidebarOpen,
                        setIsOpen: setIsInvestmentPlanAmountSidebarOpen,
                    }} />}
                {userData && userData.investmentPlanAmount && <GuidePopup
                    expenseStore={expenseStore}
                    portfolioNavStore={portfolioNavStore}
                    cumulative={cumulative}
                    expensesAsSteps={expensesAsSteps}
                    monthlyPassiveIncome={monthlyPassiveIncome}
                    userData={userData}
                    isOpen={isGuidePopupOpen} setIsOpen={setIsGuidePopupOpen}/>}
            </Layout>
    )
}
