import {Dialog, DialogPanel, DialogTitle} from "@headlessui/react";
import {XMarkIcon} from "@heroicons/react/24/outline";
import {useState} from "react";

import "react-datepicker/dist/react-datepicker.css";
import {SubmitButton} from "../Button";

import {CheckIcon, HandThumbUpIcon, UserIcon} from '@heroicons/react/20/solid'
import {Battery0Icon, CircleStackIcon, InformationCircleIcon, XCircleIcon} from "@heroicons/react/16/solid";
import {decodeLoggedAt, toDateTime} from "../../utils/utils";
import {allocateDeposits, compactRanges, convertRangesToTimeline, generateRanges} from "../../utils/depositChainUtils";

const timeline = [
    {
        id: 1,
        content: 'No deposit for this month',
        target: '',
        href: '#',
        date: 'Jan 1',
        datetime: '2020-09-20',
        icon: XCircleIcon,
        iconBackground: 'bg-gray-400',
    },
    {
        id: 2,
        content: '100k deposited on Feb 15',
        target: '',
        href: '#',
        date: 'Feb 1',
        datetime: '2020-09-22',
        icon: InformationCircleIcon,
        iconBackground: 'bg-yellow-500',
    },
    {
        id: 3,
        content: '150k deposited on Mar 20',
        target: '',
        href: '#',
        date: 'Mar 1',
        datetime: '2020-09-28',
        icon: CheckIcon,
        iconBackground: 'bg-green-500',
    },
    {
        id: 4,
        content: '333k deposited on Apr 20',
        target: '',
        href: '#',
        date: 'Apr 1',
        datetime: '2020-09-30',
        icon: CheckIcon,
        iconBackground: 'bg-green-500',
    },
    {
        id: 4,
        content: '333k deposited on Apr 20',
        target: '',
        href: '#',
        date: 'Mar 1',
        datetime: '2020-09-30',
        icon: CheckIcon,
        iconBackground: 'bg-green-500',
    },
    {
        id: 5,
        content: '33k extra deposited on Apr 20',
        target: '',
        href: '#',
        date: 'May 1',
        datetime: '2020-10-04',
        icon: HandThumbUpIcon,
        iconBackground: 'bg-blue-500',
    },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const InvestmentPlanAmountSidebar = ({userData, amount, isOpen, setIsOpen, updateInvestmentPlanAmount}) => {
    const [newAmount, setNewAmount] = useState(amount) // amount in major currency

    const startDate = new Date("2024-01-01")

    const investmentPlanEntries = (userData.investmentPlanEntries || []).map(entry => ({
        ...entry,
        loggedAt: toDateTime(decodeLoggedAt(entry.loggedAt))
    }));

    // TODO: change to proper calc
    const acc = new Array(12).fill({});

    for (let i = 0; i < acc.length; i++) {
        acc[i] = {
            month: i,
            amount: 0,
            entries: [],
        }
    }

    const ranges = generateRanges((new Date()).getFullYear())
    // const ranges = generateRanges(2024)
    console.log('InvestmentPlanAmountSidebar:ranges', ranges)
    const filteredEntries = investmentPlanEntries.filter(entry => entry.loggedAt >= ranges[0].start && entry.loggedAt <= ranges[ranges.length - 1].end).sort((a, b) => a.loggedAt - b.loggedAt)
    console.log('InvestmentPlanAmountSidebar:filteredEntries', filteredEntries)

    const allocatedRanges = compactRanges(allocateDeposits(ranges, filteredEntries, amount * 100))
    console.log('InvestmentPlanAmountSidebar:allocatedRanges', allocatedRanges)

    const timeline = convertRangesToTimeline(allocatedRanges, amount * 100)

    console.log('InvestmentPlanAmountSidebar:investmentPlanEntries', {investmentPlanEntries})

    return <Dialog open={isOpen} onClose={setIsOpen} className="relative z-40">
        <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                    <DialogPanel
                        transition
                        className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
                    >
                        <form className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                            <div className="h-0 flex-1 overflow-y-auto">
                                <div className="bg-indigo-700 px-4 py-6 sm:px-6">
                                    <div className="flex items-center justify-between">
                                        <DialogTitle className="text-base font-semibold leading-6 text-white">🎯 Set
                                            Monthly Investment Amount</DialogTitle>
                                        <div className="ml-3 flex h-7 items-center">
                                            <button
                                                type="button"
                                                onClick={() => setIsOpen(false)}
                                                className="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                            >
                                                <span className="absolute -inset-2.5"/>
                                                <span className="sr-only">Close panel</span>
                                                <XMarkIcon aria-hidden="true" className="h-6 w-6"/>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="mt-1">
                                        <p className="text-sm text-indigo-300">
                                            What amount do you plan to invest monthly?
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-1 flex-col justify-between">
                                    <div className="divide-y divide-gray-200 px-4 sm:px-6">
                                        <div className="space-y-6 pb-5 pt-6">
                                            <div>
                                                <label htmlFor="description"
                                                       className="block text-sm font-medium leading-6 text-gray-900">
                                                    Amount that you plan to invest monthly
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        id="value"
                                                        name="value"
                                                        type="number"
                                                        value={newAmount}
                                                        onChange={(e) => setNewAmount(e.target.value)}
                                                        rows={4}
                                                        className="ddblock w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr className={"mt-4"}/>
                                <div className="flow-root px-6 mt-8">
                                    <h1 className="text-md font-normal text-gray-900 mb-4">{new Date().getFullYear()} Investement Plan</h1>
                                    <ul role="list" className="">
                                        {timeline.map((event, eventIdx) => (
                                            <li key={event.id}>
                                                <div className="relative pb-8">
                                                    {eventIdx !== timeline.length - 1 ? (
                                                        <span aria-hidden="true"
                                                              className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"/>
                                                    ) : null}
                                                    <div className="relative flex space-x-3">
                                                        <div>
                                <span
                                    className={classNames(
                                        event.iconBackground,
                                        'flex size-8 items-center justify-center rounded-full ring-8 ring-white',
                                    )}
                                >
                                <event.icon aria-hidden="true" className="size-5 text-white"/>
                                </span>
                                                        </div>
                                                        <div
                                                            className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                                                            <div>
                                                                <p className="text-sm text-gray-500">
                                                                    {event.content}{' '}
                                                                    <a href={event.href}
                                                                       className="font-medium text-gray-900">
                                                                        {event.target}
                                                                    </a>
                                                                </p>
                                                            </div>
                                                            <div
                                                                className="whitespace-nowrap text-right text-sm text-gray-500">
                                                                <time dateTime={event.datetime}>{event.date}</time>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>


                            </div>
                            <div className="flex flex-shrink-0 justify-end px-4 py-4">
                                <button
                                    type="button"
                                    onClick={() => setIsOpen(false)}
                                    className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                >
                                    Cancel
                                </button>
                                <SubmitButton
                                    text={"Update"}
                                    loadingText={"Updating..."}
                                    onSubmit={async () => {

                                        await updateInvestmentPlanAmount(newAmount)
                                        setIsOpen(false)
                                    }}/>
                            </div>
                        </form>
                    </DialogPanel>
                </div>
            </div>
        </div>
    </Dialog>
}
