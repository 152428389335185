import {Dialog, DialogPanel, DialogTitle} from "@headlessui/react";
import {XMarkIcon} from "@heroicons/react/24/outline";
import {useEffect, useState} from "react";
import useExpenses from "../../hooks/useExpenses";
import useUserDataStore from "../../hooks/useUserDataStore";
import {useAuth} from "../../providers/authprovider";
import DatePicker from "react-datepicker";
import {SubmitButton} from "../Button";
import CurrencyInput from "react-currency-input-field";
import {CURRENCIES, formatMoney} from "../../utils/utils";
import {formatDate} from "date-fns";
import BrokerDeleteConfirmation from "./BrokerDeleteConfirmation";


export const EditBrokerSidebar = ({isOpen, setIsOpen, brokersStore}) => {
    const [name, setName] = useState("")
    const [currentNavInBrokerCurrencyInCents, setCurrentNavInBrokerCurrencyInCents] = useState(0)
    const [currency, setCurrency] = useState(brokersStore.currentBroker.currency)

    const [isDeleteOpen, setIsDeleteOpen] = useState(false)

    const {
        userData,
    } = useUserDataStore()

    console.log('EditGoalSidebar currentBroker: ', brokersStore.currentBroker)

    useEffect(() => {
        if (!brokersStore.currentBroker) {
            return
        }

        setName(brokersStore.currentBroker.name || "")
        setCurrentNavInBrokerCurrencyInCents(brokersStore.currentBroker.currentNavInBrokerCurrencyInCents || 0)
        setCurrency(brokersStore.currentBroker.currency)
    }, [brokersStore.currentBroker])

    if (!userData) {
        return null
    }

    return <Dialog open={isOpen} onClose={setIsOpen} className="relative z-10">
        <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                    <DialogPanel
                        transition
                        className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
                    >
                        <form className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                            <div className="h-0 flex-1 overflow-y-auto">
                                <div className="bg-indigo-700 px-4 py-6 sm:px-6">
                                    <div className="flex items-center justify-between">
                                        <DialogTitle className="text-base font-semibold leading-6 text-white">Edit "{brokersStore.currentBroker.name}"</DialogTitle>
                                        <div className="ml-3 flex h-7 items-center">
                                            <button
                                                type="button"
                                                onClick={() => setIsDeleteOpen(false)}
                                                className="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                            >
                                                <span className="absolute -inset-2.5" />
                                                <span className="sr-only">Close panel</span>
                                                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                                            </button>
                                        </div>
                                    </div>
                                    {/*<div className="mt-1">*/}
                                    {/*    <p className="text-sm text-indigo-300">*/}
                                    {/*        Get started by filling in the information below to create your new expense.*/}
                                    {/*    </p>*/}
                                    {/*</div>*/}
                                </div>
                                <div className="flex flex-1 flex-col justify-between">
                                    <div className="divide-y divide-gray-200 px-4 sm:px-6">
                                        <div className="space-y-6 pb-5 pt-6">
                                            <div>
                                                <label htmlFor="project-name" className="block text-sm font-medium leading-6 text-gray-900">
                                                    Broker Name
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        id="project-name"
                                                        name="project-name"
                                                        type="text"
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                                                    Current NAV in Broker Currency
                                                </label>
                                                <div className="mt-2">
                                                    <CurrencyInput
                                                        id="input-example"
                                                        name="input-name"
                                                        placeholder="Please enter a amount"
                                                        defaultValue={0}
                                                        value={currentNavInBrokerCurrencyInCents/100}
                                                        className="ddblock w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        decimalsLimit={2}
                                                        onValueChange={(value, name, values) => setCurrentNavInBrokerCurrencyInCents(parseFloat(value || 0) * 100)}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                                                    Broker Currency
                                                </label>
                                                <div className="mt-2">
                                                    <div className="mt-2">
                                                        <select
                                                            id="location"
                                                            name="location"
                                                            value={currency || "USD"}
                                                            onChange={(e) => { setCurrency(e.target.value)}}
                                                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        >
                                                            {Object.keys(CURRENCIES).map((currency) => (
                                                                <option key={currency} value={currency}>{currency}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <a className={"text-red-700 font-medium underline"} href={"#"} onClick={() => setIsDeleteOpen(true)}>Want to delete this broker?</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-shrink-0 justify-end px-4 py-4">
                                <button
                                    type="button"
                                    onClick={() => setIsDeleteOpen(false)}
                                    className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                >
                                    Cancel
                                </button>
                                <SubmitButton
                                    text="Save"
                                    loadingText={"Saving..."}
                                    onSubmit={async (e) => {
                                        e.preventDefault()
                                        await brokersStore.updateBroker({
                                            id: brokersStore.currentBroker.id,
                                            name,
                                            currency,
                                            currentNavInBrokerCurrencyInCents,
                                        })
                                        setIsOpen(false)
                                        setIsDeleteOpen(false)
                                    }}
                                />
                            </div>
                        </form>
                    </DialogPanel>
                </div>
            </div>
        </div>
        <BrokerDeleteConfirmation isOpen={isDeleteOpen} setIsOpen={setIsDeleteOpen} deleteBroker={() => {
            setIsOpen(false)
            brokersStore.deleteCurrentBroker()
        }}/>
    </Dialog>
}
