import {useAuth} from "../providers/authprovider";
import {Fragment, useMemo, useState} from "react";
import useUserDataStore from "../hooks/useUserDataStore";
import Goal from "../components/Goals/Goal";
import useGoalStore from "../hooks/useGoalStore";
import {PlusCircleIcon} from "@heroicons/react/16/solid";
import {Layout} from "../components/Layout";
import {NewGoalSidebar} from "../components/Goals/NewGoalSidebar";
import {EditGoalSidebar} from "../components/Goals/EditGoalSidebar";

const navigation = [
    { name: 'Home', href: '/', current: false },
    { name: 'Goals', href: '/goals', current: true },
    // { name: 'Team', href: '#', current: false },
    // { name: 'Projects', href: '#', current: false },
    // { name: 'Calendar', href: '#', current: false },
    // { name: 'Reports', href: '#', current: false },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const stats = [
    { name: 'Invest CZK 50,000 by 2025', stat: '71,897', previousStat: '70,946', change: '12%', changeType: 'increase' },
    { name: 'Reach ~250,000 USD Portfolio Value', stat: '58.16%', previousStat: '56.14%', change: '2.02%', changeType: 'increase' },
    { name: 'Avg. Click Rate', stat: '24.57%', previousStat: '28.62%', change: '4.05%', changeType: 'decrease' },
]

export const Goals = () => {
    const authData = useAuth()
    const {userData} = useUserDataStore()
    const goalStore = useGoalStore(userData?.accountId)
    const [isNewGoalSidebarOpen, setIsNewGoalSidebarOpen] = useState(false)
    const [isEditGoalSidebarOpen, setIsEditGoalSidebarOpen] = useState(false)
    // const updateExpenseSidebarData = useUpdateExpenseSidebar()

    console.log('Goals:goals', goalStore.goals)

    console.log('App auth: ', authData)

        if (!authData.currentUser) return null

        const user = {
            name: authData.currentUser.displayName,
            email: authData.currentUser.email,
            imageUrl: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        }

    return <Layout pageSlug="goals"
    >
        <div className="h-screen mx-auto pt-8 max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
            <h1 className="text-3xl ml-1 font-bold text-gray-900 mb-8">🎯 Goals</h1>
            {goalStore.goals.map((goal) => (
                <>
                <div className="bg-white border-gray-100 border-2 rounded-lg px-5 py-6 shadow sm:px-6 mb-10">
                    <Goal
                        goal={goal}
                        selectGoal={(goal) => {
                            setIsEditGoalSidebarOpen(true)
                            goalStore.selectGoal(goal)
                        }}
                        type={goal.type}
                        customTitle={goal.customTitle}
                        createdAt={goal.createdAt}
                        deadlineAt={goal.deadlineAt}
                        targetAmountInCents={goal.targetAmountInCents}
                        currentAmountSavedInCents={goal.currentAmountSavedInCents}
                        investmentPlanEntries={userData?.investmentPlanEntries}
                    />
                </div>
                </>))}
            <div className="mt-10 bg-white border-gray-100 border-2 rounded-lg px-5 py-6 shadow sm:px-6 mb-3">
                    <a href="#" className={"flex align-bottom text-indigo-700"} onClick={() => setIsNewGoalSidebarOpen(true)} >
                        <PlusCircleIcon className="h-7 w-7 inline-block mr-2 text-indigo-500" />
                        Add new goal
                    </a>
            </div>
        </div>
        <NewGoalSidebar isOpen={isNewGoalSidebarOpen} setIsOpen={setIsNewGoalSidebarOpen} goalStore={goalStore} />
        <EditGoalSidebar isOpen={isEditGoalSidebarOpen} setIsOpen={setIsEditGoalSidebarOpen} goalStore={goalStore} />
    </Layout>
}
