import {AuthProvider} from "./providers/authprovider";
import Login from "./pages/Login";
import {App} from "./pages/App";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {Goals} from "./pages/Goals";
import Landing from "./pages/Landing";
import {MainApp} from "./pages/MainApp";
import {Guide} from "./pages/Guide";
import {Demo} from "./pages/Demo";
import {Settings} from "./pages/Settings";
import Signup from "./pages/Signup";
import {DemoSettings} from "./pages/DemoSettings";
import {Terms} from "./pages/Terms";
import {DemoGoals} from "./pages/DemoGoals";
import {Brokers} from "./pages/Brokers";
import {Journal} from "./pages/Journal";

export default function Root({children}) {
    const router = createBrowserRouter([
        // {
        //     path: "/home",
        //     element: <App />,
        // },
        {
            path: "/app/brokers",
            element: <Brokers />,
        },
        {
            path: "/app/journal",
            element: <Journal />,
        },
        {
            path: "/app/goals",
            element: <Goals />,
        },
        {
            path: "/app/terms",
            element: <Terms />
        },
        {
            path: "/demo/terms",
            element: <Terms mode={"demo"} />
        },
        {
            path: "/app",
            element: <MainApp />,
        },
        {
            path: "/app/settings",
            element: <Settings />,
        },
        {
            path: "/",
            element: <Login />,
        }, {
            path: "/login",
            element: <Login />,
        }, {
            path: "/signup",
            element: <Signup />,
        },{
            path: "/guide",
            element: <Guide />,
        }, {
            path: "/demo",
            element: <Demo />,
        },
        {
            path: "/demo/settings",
            element: <DemoSettings />,
        },
        {
            path: "/demo/goals",
            element: <DemoGoals />
        },
    ]);

    return (
        <AuthProvider>
            <RouterProvider router={router} />
        </AuthProvider>
    )
}
