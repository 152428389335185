import {useAuth} from "../providers/authprovider";
import useUserDataStore from "../hooks/useUserDataStore";
import {Layout} from "../components/Layout";
import {formatMoney} from "../utils/utils";
import {useCurrencyExchange} from "../hooks/useCurrencyExchange";
import {useEffect, useState} from "react";
import {useBrokerStore} from "../hooks/useBrokerStore";
import {NewBrokerSidebar} from "../components/Brokers/NewBrokerSidebar";
import {EditGoalSidebar} from "../components/Goals/EditGoalSidebar";
import {EditBrokerSidebar} from "../components/Brokers/EditBrokerSidebar";


// const projects = [
//     {id: 1, name: 'Ed: Trading 212', currentNavInBrokerCurrencyInCents: '9471500', currency: 'USD'},
//     {id: 2, name: 'Ed: Degiro', currentNavInBrokerCurrencyInCents: '1600000', currency: 'EUR'},
//     {id: 3, name: 'Ed: Etoro', currentNavInBrokerCurrencyInCents: '6000000', currency: 'USD'},
//     {id: 4, name: 'Ed: Cash', currentNavInBrokerCurrencyInCents: '47000000', currency: 'CZK'},
// ]

export const Brokers = () => {
    const [isNewBrokerSidebarOpen, setIsNewBrokerSidebarOpen] = useState(false)
    const [isEditBrokerSidebarOpen, setIsEditBrokerSidebarOpen] = useState(false)
    const authData = useAuth()
    //fetching global currency
    const {userData} = useUserDataStore()
    const brokersStore = useBrokerStore(userData?.accountId)
    console.log('Brokers:userData', userData)
    const {convert, getExchangeRate, isReady} = useCurrencyExchange()

    useEffect(() => {
        if (userData?.currency) {
            getExchangeRate(userData?.currency)
        }
    }, [userData?.currency])

    console.log('Brokers auth: ', authData)

    if (!isReady) {
        return null
    }

    if (!userData) {
        return <div>Loading...</div>;
    }

    if (!authData.currentUser) return null

    const selectBroker = (broker) => {
        brokersStore.selectBroker(broker)
        setIsEditBrokerSidebarOpen(true)
    }

    const total = () => brokersStore.brokers.reduce((acc, project) => {
        return acc + convert(project.currentNavInBrokerCurrencyInCents / 100, project.currency)
    }, 0)

    return <Layout pageSlug="brokers"
    >
        <div className="h-screen mx-auto pt-8 max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
            <h1 className="text-3xl ml-1 font-bold text-gray-900 mb-8">🏦 Brokers</h1>


                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <p className="mt-2 text-sm text-gray-700">
                            Here you can list down all the brokers you use, and update their current NAV.
                        </p>
                    </div>
                    <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                        <button
                            type="button"
                            onClick={() => setIsNewBrokerSidebarOpen(true)}
                            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Add broker
                        </button>
                    </div>
                </div>
                <div className="-mx-4 mt-8 flow-root sm:mx-0">
                    <table className="min-w-full">
                        <colgroup>
                            <col className="w-full sm:w-1/2"/>
                            <col className="sm:w-1/6"/>
                            <col className="sm:w-1/6"/>
                            <col className="sm:w-1/6"/>
                        </colgroup>
                        <thead className="border-b border-gray-300 text-gray-900">
                        <tr>
                            <th scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                Broker Name
                            </th>
                            <th scope="col"
                                className="hidden py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:table-cell">
                                Broker <br/>Currency
                            </th>
                            <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                            >
                                Current NAV in Broker Currency
                            </th>
                            <th
                                scope="col"
                                className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                            >
                                Current Nav in Base Currency
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {brokersStore.brokers.map((project) => (
                            <tr key={project.id} className="border-b border-gray-200">
                                <td className="max-w-0 py-5 pl-4 pr-3 text-sm sm:pl-0">
                                    <div className="font-medium text-indigo-600 underline">
                                        <a href={'#'} onClick={() => selectBroker(project)}>{project.name}</a></div>
                                </td>
                                <td className="hidden px-3 py-5 text-right text-sm text-gray-500 sm:table-cell">{project.currency}</td>
                                <td className="hidden px-3 py-5 text-right text-sm text-gray-500 sm:table-cell">{formatMoney(project.currentNavInBrokerCurrencyInCents / 100, project.currency)}</td>
                                <td className="py-5 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-0">{formatMoney(convert(project.currentNavInBrokerCurrencyInCents / 100, project.currency) )}</td>
                            </tr>
                        ))}
                        </tbody>
                        <tfoot>
                        <tr>
                            <th
                                scope="row"
                                colSpan={3}
                                className="hidden pl-4 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell sm:pl-0"
                            >
                                Total
                            </th>
                            <th scope="row"
                                className="pl-4 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden">
                                Total
                            </th>
                            <td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-0">{formatMoney(total())}</td>
                        </tr>
                        </tfoot>
                    </table>
            </div>
        </div>
        <NewBrokerSidebar isOpen={isNewBrokerSidebarOpen} setIsOpen={setIsNewBrokerSidebarOpen} brokersStore={brokersStore} />
        {brokersStore.currentBroker && <EditBrokerSidebar isOpen={isEditBrokerSidebarOpen} setIsOpen={setIsEditBrokerSidebarOpen} brokersStore={brokersStore} />}
    </Layout>
}
