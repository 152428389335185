import {Layout} from "../components/Layout";
import useUserDataStore from "../hooks/useUserDataStore";
import {useEffect, useState} from "react";
import {SubmitButton} from "../components/Button";
import {CURRENCIES} from "../utils/utils";
import useGoalStore from "../hooks/useGoalStore";
import {useJournalStore} from "../hooks/useJournalStore";
import {formatDate} from "date-fns";
import Editor from "react-simple-wysiwyg";

export const Journal = () => {
    const {
        userData,
    } = useUserDataStore()
    const journalStore = useJournalStore(userData?.accountId)
    const [html, setHtml] = useState('');

    console.log('Journal: journalStore', journalStore.journalEntries)

    function onChange(e) {
        setHtml(e.target.value);
    }

    if (!userData) {
        return null
    }

    return (
        <Layout pageSlug="journal">
            <div className="px-10 lg:px-64 py-10">
                <div className="space-y-10 divide-y divide-gray-900/10">
                    <h1 class="text-3xl ml-1 font-bold text-gray-900 mb-8">📙 Journal</h1>
                    <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-1">

                        <div>
                            <form>
                        <Editor style={{height: '150px'}} value={html} onChange={onChange} />
                        <div className="flex flex-shrink-0 justify-end my-4">
                            <SubmitButton onSubmit={() => journalStore.addJournalEntry({content: html})} text="Save"></SubmitButton>
                        </div>
                            </form>
                            <hr/>
                            </div>

                        {journalStore.journalEntries.map((entry) =>
                            <article class="p-6 bg-white rounded-lg border border-gray-200 shadow-md ">
                                <div class="flex justify-between items-center mb-5 text-gray-900">
                  {/*<span class="bg-primary-100 text-primary-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-primary-200 dark:text-primary-800">*/}
                  {/*    <svg class="mr-1 w-3 h-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"></path></svg>*/}
                  {/*    Tutorial*/}
                  {/*</span>*/}
                                    <span class="text-sm">{formatDate(entry.createdAt, "MMM dd, yyyy")}</span>
                                </div>
                                {/*<h2 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white"><a href="#">How to quickly deploy a static website</a></h2>*/}
                                <p class="mb-5 font-light text-gray-900" dangerouslySetInnerHTML={{__html: entry.content}}></p>
                            </article>
                            )}
                    </div>
                </div>
            </div>
        </Layout>
    )
}
